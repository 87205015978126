


















/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import PageMain from '@part/layout/PageMain.vue';
import lang from '@lang/login.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
    },
    props: [],
    data() {
        return {
            pwInput: '',
            pw: 'tm123'
        };
    },
    computed: {
        lang(){
            return lang[this.activeLang()];
        }
    },
    methods: {
        login() {
            let input = document.querySelector('.login__input');
            if (this.pw == this.pwInput) {
                this.$router.push('/settings')
            } else {
                this.pwInput = '';
                input.setAttribute('placeholder', 'Incorrect password. Please try again');
            }
        }
    },
    mounted() {
        // const instance = axios.create({
        //     baseURL: app.api.api,
        //     timeout: 2500,
        //     headers: {}
        // });
        // instance.get(`/page/${this.$route.meta.id}`).then(response => {
        //     this.pageData = response.data;
        //     setTitle(response);
        //     return response.data;
        // })
        // .catch(error => {
        //     console.log(`🔥${error}🔥`);
        //     handle429(this, error);
        // });
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dev extends Vue {}
