export default {
    en: {
        placeholder: 'PASSWORD',
        intro: 'For admins',
        button: 'SAVE'

    },
    nl: {
        placeholder: 'WACHTWOORD',
        intro: 'Voor admins',
        button: 'OPSLAAN'
    }
};
